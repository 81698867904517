import { Parallax, ParallaxProvider } from 'react-scroll-parallax';

import { BlueprintLines } from '../components/BlueprintLines';
import { CallForQuote } from '../components/CallForQuote';
import { Card } from '../components/Card';
import { CardTitle } from '../components/CardTitle';
import { Footer } from '../components/Footer';
import { FreeQuoteBanner } from '../components/FreeQuoteBanner';
import Image from 'next/image';
import Link from 'next/link';
import { NextSeo } from 'next-seo';
import { PageTitle } from '../components/PageTitle';
import { Reviews } from '../components/Reviews';
import logoAnimation from '../logoAnimation.json';
import styled from 'styled-components';
import { useEffect } from 'react';
import { useLottie } from 'lottie-react';
import useMatchMedia from '../lib/useMatchMedia';

type Props = {};

const MainContent = styled.div`
  min-height: 100vh;
  position: relative;
  background: linear-gradient(180deg, #ffffff, #e5e5e5);
`;

const InnerMaxWidth = styled.div`
  max-width: 1200px;
  margin: auto;
  padding: 0 24px;
  @media (max-width: 570px) {
    padding: 0 12px;
  }
`;

const HeadingInnerMaxWidth = styled(InnerMaxWidth)`
  padding-bottom: 180px;
  position: relative;
`;

const BusinessDetailsItem = styled.h3`
  font-weight: 500;
  color: var(--color-medium);
  font-size: 20px;
  margin-top: 3px;
  margin-left: var(--indent-spacing);
  display: flex;
  text-decoration: none;
  span {
    line-height: 1.1;
  }
  @media (max-width: 970px) {
    font-size: 17px;
  }
  @media (max-width: 470px) {
    font-size: 14px;
  }
`;

const Header = styled.div`
  padding-top: 20px;
  position: relative;
  z-index: 1;
  margin-top: -60px;
  background: rgba(0, 0, 0, 0.03);
  background: radial-gradient(
    circle,
    rgba(0, 0, 0, 0.001) 0%,
    rgba(0, 0, 0, 0.05) 100%
  );
  &:after {
    position: absolute;
    content: '';
    left: 10%;
    bottom: 0;
    right: 10%;
    height: 100px;
    pointer-events: none;
    background: url('/MasterDotsBG.png');
    background-size: 35px 35px;
    z-index: -1;
  }
`;

const ServiceAreasContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: var(--indent-spacing);
  margin-bottom: 12px;
  opacity: 0.9;
  margin-top: -8px;
  @media (max-width: 700px) {
    margin-top: 0px;
  }
`;

const ServiceAreaItem = styled.p`
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  margin-right: 18px;
  padding-right: 18px;
  font-size: 15px;
  @media (max-width: 570px) {
    font-size: 13px;
  }

  @media (max-width: 470px) {
    font-size: 12px;
    margin-right: 8px;
    padding-right: 8px;
  }
  @media (max-width: 370px) {
    font-size: 11px;
  }
  &:last-of-type {
    border-right: none;
  }
`;

const ServicesOfferedContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  margin-right: 300px;
  @media (max-width: 630px) {
    margin-right: 0px;
  }
  @media (max-width: 530px) {
    margin-bottom: 260px;
  }
  @media (max-width: 380px) {
    margin-bottom: 240px;
  }
  @media (max-width: 330px) {
    margin-bottom: 200px;
  }
`;

const ServiceItem = styled.p`
  font-weight: 400;
  font-size: 17px;
  width: 50%;
  margin: 10px 0 !important;
  color: var(--color-soft-dark);
  @media (max-width: 780px) {
    width: 100%;
  }
  @media (max-width: 380px) {
    font-size: 15px;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 600px;
  margin-bottom: -30px;
  margin-left: -40px;
  @media (max-width: 570px) {
    padding-top: 60px;
    margin-bottom: -20px;
    margin-left: -45px;
  }
  @media (max-width: 470px) {
    padding-top: 90px;
    margin-bottom: -15px;
    margin-left: -30px;
  }
`;

const JakeImageContainer = styled.div`
  position: absolute;
  bottom: 0px;
  right: 40px;
  display: flex;
  @media (max-width: 850px) {
    right: 10px;
  }
`;

const VeteranOwnedContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 90px;
`;

const VeteranBlueprintLine = styled.div`
  height: 2px;
  flex: 1;
  margin: 0 40px;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 0 0 3px rgba(255, 255, 255, 1);
  @media (max-width: 670px) {
    margin: 0 20px;
  }
  @media (max-width: 570px) {
    margin: 0 10px;
  }
`;

const ServicesOfferedCard = styled(Card)`
  margin-top: 0;
`;

const LeanMoreText = styled.p`
  font-weight: 700;
  margin: 0 !important;
  padding-right: 20px;
  span {
    text-decoration: none;
  }
`;

const LinkCard = styled(Card)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: transform 0.1s ease;
  opacity: 0.8;
  padding: 24px;
  margin-top: 12px;
  margin-bottom: 12px;
  &:nth-of-type(2) {
    margin-top: 36px;
  }
  &:last-of-type {
    margin-bottom: 0px;
  }
  ${LeanMoreText} {
    transition: transform 0.3s ease;
  }

  @media (max-width: 480px) {
    flex-direction: column;
    align-items: flex-start;
  }

  &:hover {
    opacity: 1;
    transform: scale(1.02);
    ${LeanMoreText} {
      transform: translateX(20px);
    }
  }
  ${CardTitle} {
    margin: 0;
    padding: 0;
    @media (max-width: 480px) {
      margin: 12px 0;
    }
    &:after {
      display: none;
    }
  }
`;

const LinkImageContainer = styled.div`
  border-radius: 8px;
  border: 3px solid #222;
  margin-right: 24px;
  overflow: hidden;
  display: flex;
`;

const AboutOwnersContainer = styled(Card)`
  background: transparent;
  box-shadow: none;
  margin-top: 60px;
`;

const Owner2ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const SERVICES_OFFERED = [
  'Ant Extermination',
  'Preventative Services',
  'Spider Extermination',
  'Wasp Extermination',
  'Pest Inspection',
  'Recurring Maintenance',
  'Termite Extermination',
];

export const Home: React.FC<Props> = () => {
  const { View, ...lottie } = useLottie({
    animationData: logoAnimation,
    autoplay: true,
    loop: false,
  });

  useEffect(() => {
    const handleAnimationComplete = () => {
      setTimeout(() => {
        lottie?.goToAndPlay(0);
      }, 18000);
    };
    lottie?.animationItem?.addEventListener &&
      lottie?.animationItem?.addEventListener(
        'complete',
        handleAnimationComplete
      );

    return () => {
      lottie?.animationItem?.removeEventListener &&
        lottie?.animationItem?.removeEventListener(
          'complete',
          handleAnimationComplete
        );
    };
  }, [lottie]);

  const mediaMoreThan700 = useMatchMedia(700);

  return (
    <ParallaxProvider>
      <MainContent>
        <FreeQuoteBanner href='tel:5128000798'>
          Call Today For A Free Quote&nbsp;&nbsp;512-800-0798
        </FreeQuoteBanner>
        <NextSeo
          description="Two exterminators' mission for the Leander and Austin, TX area    Have you hear a scurrying in your attic or seen a little mouse run across the kitchen floor? May be you have seen one of those"
          openGraph={{
            description:
              "Two exterminators' mission for the Leander and Austin, TX area    Have you hear a scurrying in your attic or seen a little mouse run across the kitchen floor? May be you have seen one of those",
          }}
        />
        <Parallax speed={-20}>
          <Header>
            <BlueprintLines className='left' />
            <BlueprintLines className='right' />
            <BlueprintLines className='top' />

            <HeadingInnerMaxWidth>
              <LogoContainer>{View}</LogoContainer>
              <PageTitle>Defense Pest Management</PageTitle>
              <ServiceAreasContainer>
                <ServiceAreaItem>
                  <span role='img' aria-label='pin'>
                    📍
                  </span>
                  &nbsp;&nbsp;Austin, TX
                </ServiceAreaItem>
                <ServiceAreaItem>
                  <span role='img' aria-label='pin'>
                    📍
                  </span>
                  &nbsp;&nbsp;Leander, TX
                </ServiceAreaItem>
                <ServiceAreaItem>
                  <span role='img' aria-label='pin'>
                    📍
                  </span>
                  &nbsp;&nbsp;Liberty Hill, TX
                </ServiceAreaItem>
              </ServiceAreasContainer>

              <BusinessDetailsItem as='a' href='tel:512-800-0798'>
                <span role='img' aria-label='phone'>
                  📞
                </span>
                &nbsp;&nbsp;512-800-0798
              </BusinessDetailsItem>
              <BusinessDetailsItem
                as='a'
                href='mail:Info@defensepestaustin.com'
              >
                <span role='img' aria-label='email'>
                  📧
                </span>
                &nbsp;&nbsp;Info@defensepestaustin.com
              </BusinessDetailsItem>
              <BusinessDetailsItem>
                <span role='img' aria-label='medal'>
                  🎖
                </span>
                &nbsp;&nbsp;Veteran Owned
              </BusinessDetailsItem>
              <BusinessDetailsItem>
                <span role='img' aria-label='hours'>
                  🕚
                </span>
                &nbsp;&nbsp;Mon - Fri 8am to 4pm
              </BusinessDetailsItem>
            </HeadingInnerMaxWidth>
          </Header>
        </Parallax>
        <InnerMaxWidth>
          <ServicesOfferedCard>
            <JakeImageContainer>
              <Image src='/OwnerImage.png' width={371} height={297} />
            </JakeImageContainer>
            <CardTitle>Services Offered</CardTitle>
            <ServicesOfferedContainer>
              {SERVICES_OFFERED.map((service) => (
                <ServiceItem key={service}>{service}</ServiceItem>
              ))}
            </ServicesOfferedContainer>
          </ServicesOfferedCard>
        </InnerMaxWidth>
        <Reviews />
        <InnerMaxWidth>
          <Card>
            <CardTitle>General Pest Control</CardTitle>
            <p>
              Do you have creepy crawlers? Are you waking up screaming because a
              spider is sharing the pillow with you? Are there Carpenter ants
              chewing on your house? Defense Pest Management can help. The first
              step to solving the problem is calling for a free quote, and
              scheduling an appointment.
            </p>
            <p>
              Not only do we have the highest level of certification offered by
              the state of Texas but more than 12 years combined experience
              servicing Leander and Austin area. Here in Texas the summers can
              be brutal, no rain and constant sunshine drives the bugs into
              homes, but that’s where we come in. We have plans ranging from one
              time treatments, to monthly mosquito treatments. No matter what
              the need we can provide services to help keep you and your family
              pest free.
            </p>
            <CallForQuote />
            <Link href='/termites'>
              <LinkCard as='a'>
                <LinkImageContainer>
                  <Image src='/termite.jpg' width={60} height={60} />
                </LinkImageContainer>
                <CardTitle>Termite Recognition & Treatment</CardTitle>
                <LeanMoreText>
                  Learn More<span> →</span>
                </LeanMoreText>
              </LinkCard>
            </Link>
            {/* <Link href='/mosquitos'>
              <LinkCard as='a'>
                <LinkImageContainer>
                  <Image src='/mosquito.jpg' width={60} height={60} />
                </LinkImageContainer>
                <CardTitle>How To Prevent Mosquitos</CardTitle>
                <LeanMoreText>
                  Learn More<span> →</span>
                </LeanMoreText>
              </LinkCard>
            </Link> */}
            <Link href='/small-animals'>
              <LinkCard as='a'>
                <LinkImageContainer>
                  <Image src='/opossum.jpg' width={60} height={60} />
                </LinkImageContainer>
                <CardTitle>Rodent/Small Animal Control</CardTitle>
                <LeanMoreText>
                  Learn More<span> →</span>
                </LeanMoreText>
              </LinkCard>
            </Link>
          </Card>
          <AboutOwnersContainer>
            <VeteranOwnedContainer>
              <VeteranBlueprintLine />

              <Image
                src='/VeteranOwned.png'
                width={mediaMoreThan700 ? 200 : 120}
                height={mediaMoreThan700 ? 200 : 120}
              />
              <VeteranBlueprintLine />
            </VeteranOwnedContainer>
            <CardTitle>About The Owners</CardTitle>
            <p>
              <strong>
                Two exterminators’ mission for the Leander and Austin, TX area
              </strong>
            </p>
            <p>
              Have you hear a scurrying in your attic or seen a little mouse run
              across the kitchen floor? May be you have seen one of those 8
              legged spiders on your bed? Give us a call to receive top quality
              treatment but get that small town feel that Austin is known for.
              Home or business we have the expertise and the equipment to get
              rid of your unwanted guest from ants, to rats, and even the hated
              scorpion we got you covered.
            </p>
            <p>
              <strong>Trevor Glass</strong>
            </p>
            <p>
              A hard working former Marine that completed his highest
              certification in termite and pest control in 2 years. He has
              served with the finest people America has to offer. Now he will
              serve you with the same determination and trustworthiness that we
              have all learned to respect out of the men in green.
            </p>

            <p>
              <strong>Jake Knodle</strong>
            </p>
            <p>
              A former Air force airman Jake has also completed the highest
              certification in termite and pest control. He has been serving the
              Austin and surrounding area for over 8 years. Jake has learned
              that doing the job right the first time is the best way to get the
              job done.
            </p>
          </AboutOwnersContainer>

          {/* <Owner2ImageContainer>
            <Image src='/OwnerImage2.png' width={244} height={227} />
          </Owner2ImageContainer> */}
        </InnerMaxWidth>
        <Footer />
      </MainContent>
    </ParallaxProvider>
  );
};

export default Home;

// export const getStaticProps: GetStaticProps = async () => {
//   const data: any[] = [];
//   return {
//     // revalidate: 43200, // 12 hours
//     props: {
//       data,
//     },
//   };
// };
